import { HttpUtilities } from '@shahadul-17/http-client';
import { configuration } from '../configuration';

export class UrlUtilities {

  /**
   * Prepares accounts URL.
   * @param {String} path Path to be appended to the accounts URL.
   * @param {Object} queryParameters Query parameters that should be added to the accounts URL.
   * @returns {String} Returns accounts URL.
   */
  static prepareAccountsUrl(path, queryParameters = undefined) {
    if (!path || !queryParameters) { return undefined; }

    const queryParameterNames = Object.keys(queryParameters);
    path = HttpUtilities.sanitizePath(path);
    path = HttpUtilities.setQueryParameters(path, queryParameterNames, queryParameters);

    let host = configuration.accountsHost;

    if (host.endsWith('/')) {
      host = host.substring(0, host.length - 1);
    }

    if (path.startsWith('/')) {
      path = path.substring(1);
    }

    return `${configuration.accountsHost}/${path}`;
  }

  static getEncodedOrigin() {
    let origin;
  
    try {
      origin = window.location.origin;
    } catch (error) {
      origin = configuration.defaultOrigin;
  
      // console.error('An error occurred while retrieving origin URL.', error);
    }
  
    return encodeURIComponent(origin);
  }
  
  static getEncodedCurrentUrl() {
    let currentUrl;
  
    try {
      currentUrl = window.location.href;
    } catch (error) {
      currentUrl = configuration.defaultOrigin;
  
      // console.error('An error occurred while retrieving current URL.', error);
    }
  
    return encodeURIComponent(currentUrl);
  }
}
