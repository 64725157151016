import { StringUtilities } from './string-utilities';

export class ArrayUtilities {

  /**
   * Finds an element in array.
   * @param {String} key Key to be used for finding.
   * @param {any} value Value to be used for finding.
   * @param {Array<any>} array Array of items to find.
   * @returns {{ index: Number, item: any }} Returns the result.
   */
  static find(key, value, array = [], defaultValue = undefined) {
    // checks if key is undefined, null or empty string...
    const hasKey = !StringUtilities.isUndefinedOrNullOrEmpty(key);

    for (let i = 0; i < array.length; i++) {
      // selects an item from the array...
      const item = array[i];

      // if key is undefined, null or empty, we shall compare
      // the value with the item itself...
      if (!hasKey && item === value) { return { index: i, item: item, }; }
      // if key is provided but the item is not an object, we'll continue...
      if (hasKey && !item || typeof item !== 'object') { continue; }

      if (hasKey && item[key] === value) { return { index: i, item: item, }; }
    }

    return { index: -1, item: defaultValue, };
  }

  /**
   * Sorts an array.
   * @param {String} key Key to be used for sorting.
   * @param {Array<any>} array Array of items to sort.
   * @param {'ASC'|'DESC'} sortingOrder Order by which the array shall be sorted.
   * @returns {Array<any>} Returns the sorted array.
   */
  static sort(key, array = [], sortingOrder = 'ASC') {
    // checks if key is undefined, null or empty string...
    const hasKey = !StringUtilities.isUndefinedOrNullOrEmpty(key);

    // if key is not provided, it means the array doesn't contain object...
    if (!hasKey) {
      return array.sort((a, b) => {
        if (a < b) { return sortingOrder === 'ASC' ? -1 : 1; }
        if (a > b) { return sortingOrder === 'ASC' ? 1 : -1; }
        return 0;
      });
    }

    return array.sort((a, b) => {
      if (!a || !b || typeof a !== 'object' || typeof b !== 'object') { return 0; }

      const aValue = a[key];
      const bValue = b[key];

      if (!aValue || !bValue) { return 0; }

      if (a[key] < b[key]) { return sortingOrder === 'ASC' ? -1 : 1; }
      else if (a[key] > b[key]) { return sortingOrder === 'ASC' ? 1 : -1; }
      return 0;
    });
  }

  /**
   * Adds an element to the specified array and returns a new array
   * containing the added element.
   * @param {any} element Element to be added.
   * @param {Array<any>} array Array on which the element shall be added.
   * @returns {Array<any>} Returns a newly created array containing the element.
   */
  static addElement(value, array = []) {
    const elements = Array.isArray(array) ? [...array] : [];
    elements.push(value);

    return elements;
  }

  /**
   * Adds elements to the specified array and returns a new array
   * containing the added elements.
   * @param {Array<any>} elements Elements to be added.
   * @param {Array<any>} array Array on which the element shall be added.
   * @returns {Array<any>} Returns a newly created array containing the element.
   */
  static addElements(elements, array = [], addToFront = false) {
    if (!Array.isArray(elements) || !Array.isArray(array)) { return [...array]; }
    
    const _elements = [];

    if (addToFront) {
      _elements.push(...elements);
      _elements.push(...array);

      return _elements;
    }

    _elements.push(...array);
    _elements.push(...elements);

    return _elements;
  }

  /**
   * Updates an element inside array and returns the updated.
   * @param {String} key Key to be used for finding.
   * @param {any} value Value to be used for finding.
   * @param {any} updatedValue Value to be updated.
   * @param {Array<any>} array 
   * @returns {Array<any>} Returns a new array with the updated value in position.
   */
  static updateElement(key, value, updatedValue, array=[]) {
    // returns result after finding...
    const findResult = this.find(key, value, array);

    // if index is equal to -1, we shall return the default item...
    if (findResult.index === -1) { return array; }

    const elements = [...array];
    // replaces the old value with the new one...
    elements[findResult.index] = updatedValue;

    return elements;
  }

  /**
   * Removes an element from the array and returns
   * a new array containing the remaining items.
   * @param {String} key Key to be used for finding.
   * @param {any} value Value to be used for finding.
   * @param {Array<any>} array Array of items to find.
   * @returns {Array<any>} Returns an array containing rest of the items.
   */
  static remove(key, value, array = []) {
    // checks if key is undefined, null or empty string...
    const hasKey = !StringUtilities.isUndefinedOrNullOrEmpty(key);
    const items = [];

    for (const item of array) {
      // if key is undefined, null or empty, we shall compare
      // the value with the item itself...
      if (!hasKey && item === value) { continue; }
      // if key is provided but the item is not an object, we'll continue...
      if (hasKey && !item || typeof item !== 'object') { continue; }
      // if item is found, we shall skip the item...
      if (hasKey && item[key] === value) { continue; }

      items.push(item);
    }

    return items;
  }
}
