import { configureStore } from '@reduxjs/toolkit';
import { RestApiClient, RestApiClientEvent } from '@shahadul-17/rest-api-client';
import { persistStore } from 'redux-persist';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import { configuration, restApiClientConfiguration } from './configuration';
import { persistedReducer, userActions, } from './centralized-state';
import { StringUtilities } from './utilities';

export class Startup {

  constructor() {
    this.reduxStore = undefined;
    this.persistedStore = undefined;

    this.onBeforeRequestSent = this.onBeforeRequestSent.bind(this);
    this.onDataValidationError = this.onDataValidationError.bind(this);
    this.onConnectionError = this.onConnectionError.bind(this);
    this.onResponseReceived = this.onResponseReceived.bind(this);
    this.dispatchReduxAction = this.dispatchReduxAction.bind(this);
  }

  //#region Token Provider

  getAccessToken() {
    const accessToken = this.reduxStore.getState().user?.accessToken;

    return `bearer ${accessToken}`;
  }

  getRefreshToken() {
    const refreshToken = this.reduxStore.getState().user?.refreshToken;

    return `bearer ${refreshToken}`;
  }

  isTokenExpired(response, restApiClient) { return false; }

  renewAccessTokenAsync(routeName, restApiClient) { return false; }

  //#endregion

  getDecodedAccessToken() {
    const accessToken = this.getAccessToken();

    if (StringUtilities.isUndefinedOrNullOrEmpty(accessToken, true)) { return undefined; }

    const decodedAccessToken = jwtDecode(accessToken);

    return decodedAccessToken;
  }

  /**
   * Dispatches redux action.
   * @param {{type: String, payload: any}} action Action to dispatch.
   */
  dispatchReduxAction(action) {
    if (!this.reduxStore || typeof action !== 'object') { return; }

    this.reduxStore.dispatch(action);
  }

  configureReduxStore() {
    const reduxStore = configureStore({
      reducer: persistedReducer,
      middleware: [],       // this empty array is required to make sure that no middleware is used...
    });
    const persistedStore = persistStore(reduxStore);

    return { reduxStore, persistedStore, };
  }

  /**
   * @param {import('@shahadul-17/rest-api-client').IRestApiClientEventArguments} eventArgs 
   */
  handleAuthorization(eventArgs) {
    const requestTags = eventArgs.restApiClientRequestOptions.requestTags;

    if (!requestTags?.includes('AUTHORIZATION')) { return; }

    const response = eventArgs.httpResponse?.jsonData;

    // if access token is not provided, we won't process any further...
    if (StringUtilities.isUndefinedOrNullOrEmpty(response?.accessToken, true)) { return; }

    // we'll save user info so that we can use it when needed...
    this.dispatchReduxAction(userActions.setAccessToken(data.token));
  }

  //#region Rest API Client Events

  /**
   * @param {String} toastId 
   * @param {"info" | "warn" | "success" | "error"} toastType 
   * @param {String} message 
   * @param {Array<String>} requestTags 
   * @param {String} tagToIgnore 
   * @param {Boolean} autoClose Default value is true.
   */
  showToast(toastId, toastType, message, requestTags, tagToIgnore, autoClose = true, forceSpawn = false) {
    if (requestTags?.includes(tagToIgnore)) { return; }

    // checks if please wait toast is not ignored so that we can update that toast...
    const shallUpdate = !requestTags?.includes("IGNORE_PLEASE_WAIT_TOAST");

    // if 'shallUpdate' is false, it means we need to spawn new toast...
    if (forceSpawn || !shallUpdate) {
      toast[toastType](message, { toastId: toastId, autoClose: autoClose, });

      return;
    }

    // otherwise we'll update existing toast...
    toast.update(toastId, {
      render: message,
      type: toastType,
      autoClose: autoClose,
    });
  }

  /**
   * @param {import('@shahadul-17/rest-api-client').IRestApiClientEventArguments} eventArgs 
   */
  onBeforeRequestSent(eventArgs) {
    const { additionalData, requestTags, } = eventArgs.restApiClientRequestOptions;

    this.showToast(additionalData.requestId, "info", "Please wait...",
      requestTags, "IGNORE_PLEASE_WAIT_TOAST", false, true);
  }

  /**
   * @param {import('@shahadul-17/rest-api-client').IRestApiClientEventArguments} eventArgs 
   */
  onDataValidationError(eventArgs) {
    console.error(eventArgs.httpResponse);

    const { httpResponse, restApiClientRequestOptions, } = eventArgs;
    const { additionalData, requestTags, } = restApiClientRequestOptions;

    this.showToast(additionalData.requestId, "error",
      `Mandatory ${httpResponse?.jsonData?.location?.toLowerCase() ?? ""} parameter ${httpResponse?.jsonData?.parameter ?? ""} not found.`,
      requestTags, "IGNORE_DATA_VALIDATION_ERROR_TOAST", true, true);
  }

  /**
   * @param {import('@shahadul-17/rest-api-client').IRestApiClientEventArguments} eventArgs 
   */
  onConnectionError(eventArgs) {
    console.error(eventArgs.httpResponse);

    const { additionalData, requestTags, } = eventArgs.restApiClientRequestOptions;

    this.showToast(additionalData.requestId, "error",
      `An error occurred while connecting to server (${eventArgs.httpResponse.status}).`,
      requestTags, "IGNORE_CONNECTION_ERROR_TOAST", true);
  }

  /**
   * @param {import('@shahadul-17/rest-api-client').IRestApiClientEventArguments} eventArgs 
   */
  onResponseReceived(eventArgs) {
    const { additionalData, requestTags, } = eventArgs.restApiClientRequestOptions;

    // if request tag 'DECODE_UNICODE_CHARACTERS' is found...
    if (requestTags?.includes('DECODE_UNICODE_CHARACTERS')) {
      try {
        // decodes raw data using text decoder...
        const decodedData = new TextDecoder().decode(eventArgs.httpResponse.rawData);
        // parses decoded data as JSON...
        const jsonData = JSON.parse(decodedData);

        // replaces http response json data with our newly decoded one...
        eventArgs.httpResponse.jsonData = jsonData;
      } catch (error) {
        console.error('An error occurred while trying to decode unicode response.', error);
      }
    }

    switch (eventArgs.httpResponse?.status) {
      case 200:
        this.showToast(additionalData.requestId, "success",
          eventArgs.httpResponse.requestOptions.additionalData?.message ??
          eventArgs.httpResponse.jsonData?.message ?? "Successfully processed your request.",
          requestTags, "IGNORE_SUCCESS_TOAST", true);

        break;
      case 500:
        this.showToast(additionalData.requestId, "error",
          `An internal server error occurred (${eventArgs.httpResponse.status}).`,
          requestTags, "IGNORE_SERVER_ERROR_TOAST", true);

        break;
      default:
        const message = eventArgs.httpResponse.jsonData?.message ??
          `An error occurred while processing your request (${eventArgs.httpResponse.status}).`;

        this.showToast(additionalData.requestId, "error", message,
          requestTags, "IGNORE_ERROR_TOAST", true);

        break;
    }

    this.handleAuthorization(eventArgs);
  }

  //#endregion

  async configureRestApiClientAsync() {
    const restApiClient = await RestApiClient.createInstanceAsync({
      ...restApiClientConfiguration,
      ...configuration.restApiClient,
    });
    restApiClient.setTokenProvider(this);
    restApiClient.addEventListener(RestApiClientEvent.BeforeRequestSend, this.onBeforeRequestSent);
    restApiClient.addEventListener(RestApiClientEvent.DataValidationError, this.onDataValidationError);
    restApiClient.addEventListener(RestApiClientEvent.ConnectionError, this.onConnectionError);
    restApiClient.addEventListener(RestApiClientEvent.ResponseReceive, this.onResponseReceived);

    return restApiClient;
  }

  async configureAsync() {
    const { reduxStore, persistedStore, } = this.configureReduxStore();

    this.reduxStore = reduxStore;
    this.persistedStore = persistedStore;

    // just in-case we need to dispatch a redux action...
    window.dispatchReduxAction = this.dispatchReduxAction;

    const restApiClient = await this.configureRestApiClientAsync();

    return {
      reduxStore: this.reduxStore,
      persistedStore: this.persistedStore,
      restApiClient: restApiClient,
    };
  }
}
