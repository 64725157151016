
export class MiscellaneousUtilities {

  /**
   * @param {*} sortingOrders 
   * @param {String} idFieldName 
   * @returns {String} 
   */
  static prepareSortingOrdersString(sortingOrders, idFieldName = undefined) {
    const entries = Object.entries(sortingOrders);
    let sortingOrdersString = '';

    for (const [key, value] of entries) {
      sortingOrdersString += `${key === idFieldName ? '_id' : key}-${value},`;
    }

    if (sortingOrdersString.charAt(sortingOrdersString.length - 1) === ',') {
      sortingOrdersString = sortingOrdersString.substring(0, sortingOrdersString.length - 1);
    }

    return sortingOrdersString;
  }
}
