export class StringUtilities {

  /**
   * Checks if text is a String and not 'undefined' nor 'null'.
   * @param {String} text Text that needs to be checked.
   * @returns {Boolean} Returns true if text is 'undefined' or 'null'.
   * Otherwise returns false.
   */
  static isUndefinedOrNull(text) {
    return text === undefined || text === null || typeof text !== 'string';
  }

  /**
   * Checks if text is a String and not 'undefined', 'null' nor an empty string.
   * @param {String} text Text that needs to be checked.
   * @param {Boolean} trim If true, text will be trimmed before length is checked. Default value is 'false'.
   * @returns {Boolean} Returns true if text is 'undefined', 'null' or an empty string.
   * Otherwise returns false.
   */
  static isUndefinedOrNullOrEmpty(text, trim = false) {
    if (this.isUndefinedOrNull(text)) { return true; }
    if (trim) { text = text.trim(); }

    return text.length === 0;
  }

  /**
   * Returns default value if text is 'undefined' or 'null'. Otherwise,
   * returns the original text.
   * @param {String} text Text that needs to be checked.
   * @param {any} defaultValue Default value that shall be returned if text is 'undefined' or 'null'.
   * @returns {any} Returns undefined if text is undefined/null/empty string.
   */
  static getDefaultIfUndefinedOrNull(text, defaultValue = '') {
    return this.isUndefinedOrNull(text) ? defaultValue : text;
  }

  /**
   * Returns default value if text is 'undefined' or 'null'. Otherwise,
   * returns the original text.
   * @param {String} text Text that needs to be checked.
   * @param {any} defaultValue Default value that shall be returned if text is 'undefined' or 'null'.
   * @param {Boolean} trim If true, text will be trimmed before length is checked. Default value is 'false'.
   * @returns {any} Returns undefined if text is undefined/null/empty string.
   */
  static getDefaultIfUndefinedOrNullOrEmpty(text, defaultValue = '', trim = false) {
    return this.isUndefinedOrNullOrEmpty(text, trim) ? defaultValue : text;
  }

  static truncate(data, prefixLength, suffixLength, joinedBy = "...") {
    if (typeof data !== 'string') { return data; }

    // if 'joinedBy' is undefined/null, we set to empty string...
    if (joinedBy === undefined || joinedBy === null) {
      joinedBy = "";
    }

    // if data is smaller than truncated data length,
    // we return original data...
    if (data.length < prefixLength + suffixLength + joinedBy.length) { return data; }

    const prefix = data.substring(0, prefixLength);
    const suffix = data.substring(data.length - suffixLength);
    const truncatedData = `${prefix}${joinedBy}${suffix}`;

    return truncatedData;
  }
}
